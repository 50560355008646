<template>
  <div class='body'>
    <Nav></Nav>
    <div class="banner" style="margin-bottom:12px">
        <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
          <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
            <base-img class="banner-img" :src="img.url"></base-img>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    <div class='page-body page-content flex-j-b'>
      <left-menu :activeKey='tab' :data='leftMenuData' title='下属公司' @change='changeTab'></left-menu>
      <div class='subCompany-box' style='margin-left:32px;' >
          <div class='tab-title'>{{pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].contentTitle}}</div>
          <div class='tab-desc' v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].contentDesc' v-html='pageData.subCompany[Number(tab)].contentDesc'></div>
          <div v-else class='tab-desc'>暂无简介</div>
          <div style='margin-bottom:32px;'>
            <div class='tab-img-title'>在建工程</div>
            <div class="flex-row-wrap" v-viewer v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].progress.length'>
              <div v-for="(item,index) in pageData.subCompany[Number(tab)].progress.slice(0,10)" :key="item.id + index" class="case-img">
                <base-img :src="item.url"></base-img>
                <a-popover>
                  <template slot="content">
                    <p>{{item.title}}</p>
                  </template>
                  <div class='case-img-name'>{{item.title}}</div>
                </a-popover>
              </div>
            </div>
            <div v-else>
              <a-empty description='暂无图片'></a-empty>
            </div>
            <div class='more' v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].progress.length'>
              <div class='flex-a-c' @click='$router.push("/sub_company_case_all/"+tab+"/progress")'>
                <div>更多案例</div>
                <icon-svg class="icon " icon-class="right-icon" />
              </div>
            </div>
          </div>
          <div>
            <div class='tab-img-title'>完工工程</div>
            <div class="flex-row-wrap" v-viewer v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].done.length'>
              <div v-for="(item,index) in pageData.subCompany[Number(tab)].done.slice(0,10)" :key="item.id + index" class="case-img">
                <base-img :src="item.url"></base-img>
                <a-popover>
                  <template slot="content">
                    <p>{{item.title}}</p>
                  </template>
                  <div class='case-img-name'>{{item.title}}</div>
                </a-popover>
              </div>
            </div>
            <div v-else>
              <a-empty description='暂无图片'></a-empty>
            </div>
            <div class='more' v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].done.length'>
              <div class='flex-a-c' @click='$router.push("/sub_company_case_all/"+tab+"/done")'>
                <div>更多案例</div>
                <icon-svg class="icon " icon-class="right-icon" />
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
import LeftMenu from '@/components/pageComponents/LeftMenu'
import { design } from '@/api'
export default {
  name: 'subCompany',
  components: {
    Nav,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      tab: null,
      pageData: {
        subCompany: [],
      },
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      leftMenuData: [
        {
          title: '青欣一分公司',
          key: '0',
        },
        {
          title: '青欣二分公司',
          key: '1',
        },
        {
          title: '青欣三分公司',
          key: '2',
        },
        {
          title: '青欣四分公司',
          key: '3',
        },
        {
          title: '青欣五分公司',
          key: '4',
        },
        {
          title: '青欣六分公司',
          key: '5',
        },
        {
          title: '青欣七分公司',
          key: '6',
        },
      ],
    }
  },
  watch: {
    $route() {
      if (this.$route.params.type) {
        this.tab = this.$route.params.type
      } else {
        this.tab = '0'
      }
    },
  },
  async mounted() {
    if (this.$route.params.type) {
      this.tab = this.$route.params.type
    } else {
      this.tab = '0'
    }
    this.loading = true
    await this.getDesignData()
    this.loading = false
  },
  methods: {
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'subCompany' })
      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
        if (this.pageData.subCompany.length === 0) {
          const arr = this.leftMenuData.map((item) => {
            return {
              ...item,
              contentTitle: item.title || '',
              contentDesc: '',
              progress: [],
              done: [],
            }
          })
          this.$set(this.pageData, 'subCompany', arr)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    changeTab(e) {
      this.$router.push('/sub_company_detail/' + e)
    },
  },
}
</script>

<style lang="less" scoped>
.body {
  background: #f1f1f1;
}
.page-content {
  padding: 24px 0;
}
.subCompany-box {
  min-height: 400px;
  width: 1008px;
  background: #fff;
  padding: 32px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px #ddd;
  border-radius: 8px;
}
.tab-title {
  font-size: 36px;
  color: #38b352;
  margin-bottom: 48px;
  text-align: center;
}
.tab-desc {
  font-size: 16px;
  color: #2b2b2b;
  line-height: 28px;
  margin-bottom: 64px;
  text-align: left;
  text-indent: 2em;
  // padding: 0 100px;
}
.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.flex-row-wrap .case-img {
  width: calc((100% - 64px) / 5);
  object-fit: cover;
  display: block;
  margin-right: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}
.flex-row-wrap .case-img:nth-of-type(5n) {
  margin-right: 0;
}
.flex-row-wrap .case-img:hover ::v-deep img {
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.flex-row-wrap .case-img ::v-deep .base-img {
  width: 100%;
  height: 160px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.flex-row-wrap .case-img ::v-deep img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 1s;
}
.case-img-name {
  font-size: 16px;
  line-height: 32px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.tab-img-title {
  font-size: 28px;
  color: #38b352;
  margin-bottom: 48px;
  text-align: center;
}
.more {
  font-size: 16px;
  color: #38b352;
  border-radius: 22px;
  text-align: center;
  margin: 32px auto 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .flex-a-c {
    cursor: pointer;
  }
  .icon {
    margin-left: 4px;
  }
}
</style>